import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import Skills from './components/skills'
import Timeline from './components/timeline'
import Projects from './components/projects';
// import Blog from './components/blog';

class App extends Component {
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	<Sidebar></Sidebar>
				<div id="colorlib-main">
					<Introduction></Introduction>
					<Skills></Skills>
					<Projects></Projects>
          <Timeline></Timeline>
					{/* <Blog></Blog> */}
          	</div>
      	</div>
      </div>
    );
  }
}

export default App;
