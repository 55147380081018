import React, { useState } from 'react'
import timeline from '../data/timeline.json'

function ProjectEntry(entry) {
  return (
    <div>
      <h2>{entry.name} <span text='red'>( {entry.techstack} )</span> </h2>
      <ol>
        {entry.description.map(line => <li>{line}</li>)}
      </ol>

      <br />

    </div>
  );
}

function TimeLineCard(card) {

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <article
      className="timeline-entry "
      data-animate-effect="fadeInLeft"
    >
      <div className="timeline-entry-inner">
        <div className={`timeline-icon ${card.IconColour}`}>
          <i className="icon-pen2" />
        </div>

        <div
          className={`timeline-label ${isExpanded ? "expanded" : ""}`}
          // onMouseOver={handleMouseEnter}
          // onMouseDown={handleMouseLeave}
          onClick={(event) => {
            event.preventDefault()
            setIsExpanded(!isExpanded)
          }}>
          {
            !isExpanded &&
            <h2><strong>{card.Designation} @ {card.Organization}</strong> <span>{card.Duration}</span></h2>
          }
          {isExpanded &&
            <div>

              <h2><strong>{card.Designation} @ {card.Organization}</strong> <span>{card.Duration}</span></h2>

              <ol>
                {card.Projects.map(entry => <li>{ProjectEntry(entry)}</li>)}
              </ol>
            </div>
          }

        </div>
      </div>
    </article>
  );
}

export default function Timeline() {

  const [timelineData, ] = useState(timeline);


  return (
    <div>
      <section className="colorlib-experience" data-section="timeline">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">highlights</span>
              <h2 className="colorlib-heading animate-box">Professional Experience</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="timeline-centered">

                {timelineData.MileStones.map(card => TimeLineCard(card))}


              </div>
            </div>
          </div>
        </div>

        <div className="colorlib-narrow-content">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">highlights</span>
              <h2 className="colorlib-heading animate-box">Education</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="timeline-centered">

                {timelineData.Education.map(card => TimeLineCard(card))}


              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
