import React, { useState } from 'react'

import skills from '../data/skills.json';


function SkillCard(card) {
  return (
    <div className="col-md-4 text-center animate-box">
      <div className={card.color}>
        <span className="icon">
          <i className={card.icon} />
        </span>
        <div className="desc">
          <h3> {card.name} </h3>
          <p> {card.description} </p>
        </div>
      </div>
    </div>
  )
}

export default function Skills() {

  const [jsonData, ] = useState(skills);

    return (
      <div>
        
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                {/* <span className="heading-meta">What I do?</span> */}
                <h2 className="colorlib-heading">My Skills</h2>
                {/* <p>Here are some of my expertise</p> */}

                {/* <h2 className="colorlib-heading">Here are some of my expertise</h2> */}
              </div>
            </div>
            <div className="row row-pt-md">

              {/* {console.log("cards: ", jsonData.Cards)} */}

              {jsonData.Cards.map(card => SkillCard(card))}

              {/* <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Data Structures & Algorithms</h3>
                    <p>As coming from the CS background, I have good grasp over fundamental concepts of DSA</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 text-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <h3>Dev Ops</h3>
                    <p>I am pursuing my internship with DevOps team at Juniper and working with tools like Jenkins, Docker, K8s</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    )
}