import React, { useState } from 'react'
// import PoppingCard from './popping_card'
import projects from '../data/projects.json'

function OSSProjectCard(projCard){
  return (
    <div className="col-md-4 text-center animate-box">
      <div className={`services ${projCard.color}`}>
      {/* <div className=`services $({projCard.color})` style={{ backgroundImage: `url(${(projCard.image)})` }}>*/}

        <span className="icon">
          
          <a href={projCard.url} target='_blank'  rel="noopener noreferrer"><i className="icon-github" /></a>
        </span>
        <div className="desc">
        <h3><a href={projCard.url} target='_blank' rel="noopener noreferrer">{projCard.name}</a></h3>
          <p> {projCard.description} </p>
        </div>
      </div>
    </div>
  )
}

export default function Projects() {

  const [projectsData,] = useState(projects);


  return (

    <div>

      <section className="colorlib-work" data-section="projects">
        <div className="colorlib-narrow-content">
          <div className="row">

            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
              <span className="heading-meta">My Work</span>
              <h2 className="colorlib-heading animate-box">Open Source Projects</h2>
            </div>
          </div>
          <div className="row row-pt-md">
            {/* {console.log("cards: ", projectsData)} */}

            {projectsData.OSSProjects.map(card => OSSProjectCard(card))}

          </div>
          {/* <div className="row">
            <div className="col-md-12 animate-box">
              <p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  )

}
